import { useAuthContext } from '../../hooks/useAuthContext'
import TrackForm from "./TrackForm";
import {useCollectionDrop} from "../../hooks/useCollectionDrop";

// styles
import styles from './CreateTrack.module.scss'

export default function CreateTrack() {
    const { user } = useAuthContext()
    const { trackerList, error } = useCollectionDrop(
        'customer_state', user.uid
    )
    console.log(trackerList)

    return (
        <div>
            <div className={styles.flex_col}>
                <TrackForm uid={user.uid} devices={trackerList}/>
            </div>
        </div>
    )
}