import { useAuthContext } from '../../hooks/useAuthContext'
import PlotData from "./PlotData";
import {useCollectionDropPlot} from "../../hooks/useCollectionDropPlot";

// styles
import styles from '../create_track/CreateTrack.module.scss'

export default function TrackerData() {
    const { user } = useAuthContext()
    const { trackerList2, error } = useCollectionDropPlot(
        'customer_state', user.uid
    )
    console.log(trackerList2)

    return (
        <div>
            <div className={styles.flex_col}>
                <PlotData uid={user.uid} trackers2={trackerList2}/>
            </div>
        </div>
    )
}