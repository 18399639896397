import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore"


export const useCollection = (collection_name, _query, _orderBy, _orderBy2) => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call
    const query_1 = useRef(_query).current
    const orderBy_1 = useRef(_orderBy).current
    const orderBy_2 = useRef(_orderBy2).current

    useEffect(() => {

        let refCol = collection(projectFirestore, collection_name)

        let refCollection = query(refCol, where(...query_1), orderBy(...orderBy_1), orderBy(...orderBy_2))

        const unsubscribe = onSnapshot(refCollection,snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id})
            });
            let indexMax = results[0].tracker_uid
            let usedMacs = []
            let final_results = []
            final_results.push(results[0])
            usedMacs.push(indexMax)
            results.forEach((result) => {
                if (!usedMacs.includes(result.tracker_uid)) {
                    final_results.push(result)
                    usedMacs.push(result.tracker_uid)
                }
            })

            // update state
            setDocuments(final_results)
            setError(null)
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, query_1, orderBy_1])

    return { documents, error }
}