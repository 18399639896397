import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import {collection, doc, getDoc, onSnapshot, orderBy, query, where} from "firebase/firestore"


export const useCollectionDrop = (collection_name, doc_id) => {
    const [trackerList, setTrackerList] = useState(null)
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call

    useEffect(() => {

        let refDoc = doc(projectFirestore, collection_name, doc_id)

        const unsubscribe = onSnapshot(refDoc,snapshot => {
            const data = snapshot.data();
            const final_results = Object.keys(data.sensor_track_state)
                .filter(sensorId => data.sensor_track_state[sensorId] === false) // Filter sensors with value "false"
                .map(sensorId => ({
                    id: sensorId,
                    name: sensorId // You might want to fetch the name from another source if available
                }));
            setTrackerList(final_results)
            setError(null)
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, doc_id])

    return { trackerList, error }
}

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const docRef = doc(projectFirestore, collection_name, doc_id); // Replace with your collection and document ID
    //         const docSnap = await getDoc(docRef);
    //
    //         if (docSnap.exists()) {
    //             const data = docSnap.data();
    //             const final_results = Object.keys(data.sensor_track_state)
    //                 .filter(sensorId => data.sensor_track_state[sensorId] === false) // Filter sensors with value "false"
    //                 .map(sensorId => ({
    //                     id: sensorId,
    //                     name: sensorId // You might want to fetch the name from another source if available
    //                 }));
    //             setTrackerList(final_results)
    //             console.log(final_results)
    //         }
    //     }
    //
    //     fetchData()
    // }, [collection_name, doc_id])
    //
    // return { trackerList, error }
// }