import { useEffect, useState } from 'react'
import { projectAuth } from '../firebase/config'
import { signOut } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'
// import { useFirestore } from "./useFirestore";

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch, user } = useAuthContext()
    // const { deleteDocument, response} = useFirestore('user_current_query')

    const logout = () => {
        // deleteDocument(user.uid)
        setError(null)
        setIsPending(true)

        try {
            // sign the user out

            signOut(projectAuth)

            // dispatch logout action
            dispatch({ type: 'LOGOUT' })

            // update state
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err) {
            if (!isCancelled) {
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { logout, error, isPending }
}