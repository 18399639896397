import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import { getAuth } from'firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESS_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// init firebase
initializeApp(firebaseConfig)

// init services
// const projectFirestore = firebase.firestore()
const projectAuth = getAuth()
const projectFirestore = getFirestore()

// timestamp
// const timestamp = firebase.firestore.Timestamp

export { projectAuth, projectFirestore }
// export { projectFirestore, projectAuth, timestamp }