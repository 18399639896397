import { useState } from "react";
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { useCollectionMap } from "../../hooks/useCollectionMap";
import {useAuthContext} from "../../hooks/useAuthContext";


import styles from './Home.module.scss'


export default function TrackerMap({tracker_points}) {
    const [startPosition, setStartPosition] = useState([35.0458, -85.3094]);
    const { user } = useAuthContext();
    const [open, setOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [activeMarker, setActiveMarker] = useState(null);
    const [window, setWindow] = useState(null);
    const [temp, setTemp] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [devLat, setDevLat] = useState(null);
    const [devLong, setDevLong] = useState(null);
    const [battery, setBattery] = useState(null);
    const [eta, setEta] = useState(null);
    const [deviceTime, setDeviceTime] = useState(null);
    const { mapCenter, error2 } = useCollectionMap('map_configs', user.uid)
    console.log(mapCenter);
    // if (mapCenter) {
    //     setStartPosition(mapCenter)
    // }

    const handleClick = (marker, lattitude, longitude, temperature, humidity, battery_stat, trip_eta, device_timestamp) => {
        setOpen(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setBattery(battery_stat)
        setEta(trip_eta)
        setDeviceTime(device_timestamp)
    };



    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <div className={styles.deviceInfoContentBoxMapOutline}>
                <div className={styles.deviceInfoContentBoxMap}>
                    {mapCenter && !mapCenter[2] && (<Map gestureHandling={"greedy"} defaultZoom={19} defaultCenter={{ lat: mapCenter[0], lng: mapCenter[1]}} mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                        {tracker_points.map((tracker) => (
                        <AdvancedMarker position={{ lat: tracker.device_lat_long[0], lng: tracker.device_lat_long[1]}} onClick={() => handleClick(tracker.tracker_display, tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.temp, tracker.humidity, tracker.battery_status, tracker.eta_text, tracker.device_timestamp_text)}>
                            <Pin
                                background={"#529FFB"}
                                borderColor={"#3F77BB"}
                                glyphColor={"#FCFCFC"}
                            />
                        </AdvancedMarker>
                        ))}
                        {open && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Battery: {battery}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>ETA: {eta}</p>
                                </InfoWindow>
                            </div>
                        )}
                    </Map>)}
                    {mapCenter && mapCenter[2] && (<Map gestureHandling={"greedy"} defaultZoom={19} defaultCenter={{ lat: mapCenter[0], lng: mapCenter[1]}} mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                        {tracker_points.map((tracker) => (
                            <AdvancedMarker position={{ lat: tracker.device_lat_long[0], lng: tracker.device_lat_long[1]}} onClick={() => handleClick(tracker.tracker_display, tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.temp, tracker.humidity, tracker.battery_status, tracker.eta_text, tracker.device_timestamp_text)}>
                                <Pin
                                    background={"#529FFB"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                />
                            </AdvancedMarker>
                        ))}
                        {open && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Battery: {battery}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>ETA: {eta}</p>
                                </InfoWindow>
                            </div>
                        )}
                    </Map>)}
                </div>
            </div>

        </APIProvider>

    )
}
