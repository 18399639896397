import { useReducer, useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import { doc, updateDoc, query, getDocs, collection, where } from "firebase/firestore"
import { useAuthContext } from './useAuthContext'

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null }
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null }
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload }
        default:
            return state
    }
}

export const useFirestoreButton = (collection_name) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(true)
    // const [resultValue, setResultValue] = useState(null)
    const { user } = useAuthContext()

    // collection ref

    // only dispatch is not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // update a document
    const updateDocumentTrack = async (track_uid, track_state) => {
        dispatch({type: 'IS_PENDING'})

        try {
            // const createdAt = timestamp.fromDate(new Date())
            const q = query(collection(projectFirestore, collection_name), where('track_uid', '==', track_uid));
            const querySnapshot = await getDocs(q);
            let result_state = "Unknown"
            if (track_state === "Complete") {
                result_state = "Completed"
            } else {
                result_state = "Cancelled"
            }

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref

                const updatedDocumentTrack = await updateDoc(docRef, {
                    'track_state': false,
                    'final_result': result_state
                })
                dispatchIfNotCancelled({type: 'ADDED_DOCUMENT', payload: updatedDocumentTrack})
            }
        }

        catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
        }
    }

    useEffect(() => {
        setIsCancelled(false)
    }, [])

    return { updateDocumentTrack, response }

}