import { useState } from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {useCollectionPlot} from "../../hooks/useCollectionPlot";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {useCollectionTrackId} from "../../hooks/useCollectionTrackId";
import {useFirestore} from "../../hooks/useFirestore";
import { LineChart } from '@mui/x-charts/LineChart';
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import down_logo from '../../images/download_logo.png';
import Papa from 'papaparse';

// styles
import styles from '../home/Home.module.scss'
import check_logo from "../../images/check_logo.png";
import trash_logo from "../../images/trash_icon.png";



export default function PlotData({ uid, trackers2 }) {
    const [tracker, setTracker] = useState('')
    const [open, setOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [activeMarker, setActiveMarker] = useState(null);
    const [window, setWindow] = useState(null);
    const [startPosition, setStartPosition] = useState([35.0458, -85.3094]);
    const [dateTimeStart, setDateTimeStart] = useState(dayjs())
    const [dateTimeEnd, setDateTimeEnd] = useState(dayjs())
    const { addDocument, response } = useFirestore('data_queries')
    const { plotData, error2 } = useCollectionPlot('user_current_query', uid)
    const [temp, setTemp] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [devLat, setDevLat] = useState(null);
    const [devLong, setDevLong] = useState(null);
    const [deviceTime, setDeviceTime] = useState(null);
    const [showData, setShowData] = useState(false);
    let { trackerId, error } = useCollectionTrackId('user_device_pairings', uid, tracker)

    const handleSubmit = (e) => {
        e.preventDefault()
        addDocument({
            cust_uid: uid,
            query_start_time: dateTimeStart.format(),
            query_end_time: dateTimeEnd.format(),
            tracker_display: tracker,
            tracker_uid: trackerId
        })
        setShowData(true);
    }

    const handleClick = (marker, lattitude, longitude, temperature, humidity, device_timestamp) => {
        setOpen(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
    };

    const handleDownloadCsv = () => {
        const csvData = Papa.unparse(plotData.time_step_data);

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href
            = url;
        link.setAttribute('download',
            'chart_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className={styles.deviceInfoFlexColumn}>
            <form onSubmit={handleSubmit} className={styles.deviceInfoContentBox}>
                <div className={styles.deviceInfoFlexRow}>
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Select Device:
                    </h2>
                    {trackers2 && <select
                        className={styles.content_box78}
                        id="sensorId"
                        onChange={(e) => setTracker(e.target.value)}>
                        value=tracker>
                        <option value="">Select a Tracker</option>
                        {trackers2.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>}
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Query Start Date:
                    </h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                                label="Select Date & Time"
                                value={dateTimeStart}
                                onChange={(e) => setDateTimeStart(e)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Query End Date:
                    </h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                                label="Select Date & Time"
                                value={dateTimeEnd}
                                onChange={(e) => setDateTimeEnd(e)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <button className={styles.btnCreateTRK}>
                        Get Data
                    </button>
                </div>
            </form>
            {plotData && showData && <div className={styles.deviceInfoContentBox}>
                <div className={styles.deviceInfoFlexRow}>
                    <LineChart
                        xAxis={[{
                            data: plotData.timestamps,
                            scaleType: 'utc',
                            tickMinStep: 3600000000

                        }]}
                        series={[
                            {
                                data: plotData.temps,
                                label: 'Temperature (Degrees F)',
                                color: '#3F77BB'
                            },
                        ]}
                        width={1200}
                        height={300}
                    />
                </div>
                <div className={styles.deviceInfoFlexRow}>
                    <LineChart
                        xAxis={[{
                            data: plotData.timestamps,
                            scaleType: 'utc',
                            tickMinStep: 3600000000

                        }]}
                        series={[
                            {
                                data: plotData.humidity,
                                label: 'Humidity (%)',
                                color: '#529ffb'
                            },
                        ]}
                        width={1200}
                        height={300}
                    />
                </div>
                <img className={styles.lastFlexWrapperImage} src={down_logo} alt="alt text" onClick={handleDownloadCsv}/>
            </div>}
            {plotData && showData && <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <div className={styles.deviceInfoContentBoxMapOutline}>
                    <div className={styles.deviceInfoContentBoxMap}>
                        <Map gestureHandling={"greedy"} defaultZoom={12} defaultCenter={{ lat: startPosition[0], lng: startPosition[1]}} mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                            {plotData.time_step_data.map((dataPoint) => (
                                <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClick(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text)}>
                                    <Pin
                                        background={"#529FFB"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#FCFCFC"}
                                    />
                                </AdvancedMarker>
                            ))}
                            {open && (
                                <div className={styles.deviceInfoContentBoxMapOutline}>
                                    <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                        <p>{activeMarker}</p>
                                        <p>Temperature: {temp}</p>
                                        <p>Humidity: {humidity}</p>
                                        <p>Lat: {devLat}</p>
                                        <p>Long: {devLong}</p>
                                        <p>Time: {deviceTime}</p>
                                    </InfoWindow>
                                </div>
                            )}
                        </Map>
                    </div>
                </div>

            </APIProvider>}
        </div>
    )
}