import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import {collection, doc, getDoc, onSnapshot, orderBy, query, where} from "firebase/firestore"


export const useCollectionTrackId = (collection_name, cust_id, sensor_name) => {
    const [trackerId, setTrackerId] = useState('')
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call

    useEffect(() => {

        let refDoc = doc(projectFirestore, collection_name, 'all_pairings')

        const unsubscribe = onSnapshot(refDoc,snapshot => {
            const data = snapshot.data();
            const companyKeys = Object.keys(data)
            let company = []
            companyKeys.forEach(companyKey => {
                if ((data[companyKey]['cust_ids']).includes(cust_id)) {
                    company.push(companyKey)
                }
            })
            const macKeys = Object.keys(data[company[0]])
            let final_results = []
            macKeys.forEach(macKey => {
                if ((data[company[0]][macKey]) === sensor_name) {
                    final_results.push(macKey)
                }
            })

                // .filter(sensorId => data.sensor_track_state[sensorId] === false) // Filter sensors with value "false"
                // .map(sensorId => ({
                //     id: sensorId,
                //     name: sensorId // You might want to fetch the name from another source if available
                // }));
            setTrackerId(final_results[0])
            setError(null)
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, cust_id, sensor_name])
    return { trackerId, error }
}