import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import {collection, doc, getDoc, onSnapshot, orderBy, query, where} from "firebase/firestore"


export const useCollectionPlot = (collection_name, doc_id) => {
    const [plotData, setPlotData] = useState(null)
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call

    useEffect(() => {

        let refDoc = doc(projectFirestore, collection_name, doc_id)

        const unsubscribe = onSnapshot(refDoc,snapshot => {
            if (snapshot.data()){
                const data = snapshot.data();
                const final_results = data['data']
                setPlotData(final_results)
                setError(null)
            }
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, doc_id])

    return { plotData, error }
}