import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'
import { useFirestore } from "./useFirestore";
import dayjs, {Dayjs} from 'dayjs';

export const useLogin = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [loginTime, setLoginTime] = useState(dayjs())
    const { dispatch } = useAuthContext()
    const { addDocument, response } = useFirestore('customer_login')

    const login = async (email, password) => {
        setError(null)
        setIsPending(true)
        console.log(email)

        try {
            // login
            const res = await signInWithEmailAndPassword(projectAuth, email, password)
            const cust_uid = res.user.uid
            addDocument({
                cust_uid: cust_uid
            })

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })


            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err) {
            if (!isCancelled) {
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { login, isPending, error }
}