import {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {useAuthContext} from "../../hooks/useAuthContext";
import { updatePassword } from'firebase/auth'

// styles
import styles from '../login/Login.module.css'
import styles_borrowed from '../create_track/CreateTrack.module.scss'


export default function UserSettings() {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')
    const { user } = useAuthContext()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (newPassword === newPassword2) {
            updatePassword(user, newPassword)
            navigate(`/`)
        }
        else {
            console.log('passwordsNotMatched')
        }
    }

    return (
        <div className={styles_borrowed.flex_col}>
            <form onSubmit={handleSubmit} className={styles_borrowed.contentBoxCreateTRK}>
                <div className={styles_borrowed.flexContainerCreateCol_login}>
                    <h2 className={styles_borrowed.createTRKTitle}>
                        Change Password
                    </h2>
                    <div className={styles_borrowed.infoTRKName_login}>
                        New Password
                    </div>
                    <div className={styles_borrowed.content_box4_login}>
                        <input className={styles_borrowed.content_box4_input_login}
                               type="password"
                               onChange={(e) => setNewPassword(e.target.value)}
                               value={newPassword}
                        />
                    </div>
                    <div className={styles_borrowed.infoTRKName_login}>
                        Confirm New Password
                    </div>
                    <div className={styles_borrowed.content_box4_login}>
                        <input className={styles_borrowed.content_box4_input_login}
                               type="password"
                               onChange={(e) => setNewPassword2(e.target.value)}
                               value={newPassword2}
                        />
                    </div>
                    <div className={styles_borrowed.flexContainerActionButtons_login}>
                        <button className="btn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}