import {useState} from 'react'
import { useLogin } from '../../hooks/useLogin'

// styles
import styles from './Login.module.css'
import styles_borrowed from '../create_track/CreateTrack.module.scss'


export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isPending } = useLogin()

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }

    return (
        <div className={styles_borrowed.flex_col}>
            <form onSubmit={handleSubmit} className={styles_borrowed.contentBoxCreateTRK}>
                <div className={styles_borrowed.flexContainerCreateCol_login}>
                    <h2 className={styles_borrowed.createTRKTitle}>
                        BridgTRK Login
                    </h2>
                    <div className={styles_borrowed.infoTRKName_login}>
                        Email
                    </div>
                    <div className={styles_borrowed.content_box4_login}>
                        <input className={styles_borrowed.content_box4_input_login}
                               type="email"
                               onChange={(e) => setEmail(e.target.value)}
                               value={email}
                        />
                    </div>
                    <div className={styles_borrowed.infoTRKName_login}>
                        Password
                    </div>
                    <div className={styles_borrowed.content_box4_login}>
                        <input className={styles_borrowed.content_box4_input_login}
                               type="password"
                               onChange={(e) => setPassword(e.target.value)}
                               value={password}
                        />
                    </div>
                    <div className={styles_borrowed.flexContainerActionButtons_login}>
                        {!isPending && <button className="btn">Login</button>}
                        {isPending && <button className="btn" disabled>loading</button>}
                        {error && <p>{error}</p>}
                    </div>
                </div>
            </form>
        </div>
    )
}