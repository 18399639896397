import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'


// styles
import styles from './Home.module.scss'

// // components
// import TransactionForm from './TransactionForm'
import TrackerList from './TrackerList'
import TrackerMap from "./TrackerMap";

export default function Home() {
    const { user } = useAuthContext()
    const { documents, error } = useCollection(
         'tracker_rt_data', ["cust_uid", "==", user.uid], ['now_test', 'desc'], ['light_color', 'asc']
    )

    return (
        // <div>
        //     <p>BridgTRK Future Dashboard</p>
        // </div>
        <div>
            <div className={styles.flex_col}>
                {documents && <TrackerMap tracker_points={documents}/>}
                {error && <p>{error}</p>}
                {documents && <TrackerList trackers={documents} />}
            </div>
        </div>
    )
}