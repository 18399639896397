import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { useFirestore } from '../../hooks/useFirestore'
import { useFirestoreDrop } from "../../hooks/useFirestoreDrop";
import dayjs, {Dayjs} from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import Switch from '@mui/material/Switch';
import lock from '../../images/lock.png';
import { useCollectionTrackId } from "../../hooks/useCollectionTrackId";
import { v4 as uuidv4 } from 'uuid'

// styles
import styles from './CreateTrack.module.scss'



export default function TrackForm({ uid, devices }) {
    const navigate = useNavigate();
    const [track, setTrack] = useState('')
    const [highTemp, setHighTemp] = useState(100)
    const [lowTemp, setLowTemp] = useState(0)
    const [highHumid, setHighHumid] = useState(100)
    const [lowHumid, setLowHumid] = useState(0)
    const [sensor, setSensor] = useState('')
    const [showStartSuggestions, setShowStartSuggestions] = useState(false);
    const [showEndSuggestions, setShowEndSuggestions] = useState(false);
    const [targetArrivalDateTime, setTargetArrivalDateTime] = useState(new Date());
    const { addDocument, response } = useFirestore('track_info')
    const { updateDocument, response2 } = useFirestoreDrop("customer_state")
    const [dateTime, setDateTime] = useState(dayjs())
    const [trackStart, setTrackStart] = useState(dayjs())
    const [state, setState] = useState({
        temp: false,
        humidity: false
    })
    const [geo, setGeo] = useState([])
    let { trackerId, error } = useCollectionTrackId('user_device_pairings', uid, sensor)
    const {
        ready: startReady,
        value: startAddress,
        suggestions: { status, data: startSuggestions },
        setValue: setStartAddress,
        clearSuggestions: clearStartSuggestions
    } = usePlacesAutocomplete();

    const {
        ready: endReady,
        value: endAddress,
        suggestions: { status: endStatus, data: endSuggestions },
        setValue: setEndAddress,
        clearSuggestions: clearEndSuggestions
    } = usePlacesAutocomplete();

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        let newUid = uuidv4()
        addDocument({
            cust_uid: uid,
            track_display: track,
            tracker_display: sensor,
            start_address: startAddress,
            end_address: endAddress,
            track_start_time: trackStart.format(),
            target_arrival: dateTime.format(),
            target_lat_long: geo,
            temp_alert_high: Number(highTemp),
            temp_alert_low: Number(lowTemp),
            humid_alert_high: Number(highHumid),
            humid_alert_low: Number(lowHumid),
            door_trigger: false,
            final_result: "Pending",
            track_state: true,
            tracker_uid: trackerId,
            track_uid: newUid

        })
        let senseString = String(sensor)
        updateDocument(senseString)
        navigate(`/`)
    }

    const handleClickCreate = () => {
        navigate(`/`)
    };

    // const handleGeocode = (latlong) => {
    //     setGeo(latlong)
    //     console.log(latlong)
    //     console.log(geo)
    // }

    const startInputRef = useRef(null);
    const endInputRef = useRef(null);

    const handleStartInputChange = (event) => {
        setStartAddress(event.target.value)
        setShowStartSuggestions(true)
    };

    const handleEndInputChange = (event) => {
        setEndAddress(event.target.value)
        setShowEndSuggestions(true)
    };

    const handleSelectStartSuggestion = (suggestion) => {
        setStartAddress(suggestion.description);
        clearStartSuggestions(); // Clear suggestions after selection
        setShowStartSuggestions(false)
        // Optionally, you can get more details about the place using the place_id
        // const placeDetails = await getDetails({ placeId: suggestion.place_id });
        // console.log(placeDetails);
    };

    const handleSelectEndSuggestion = (suggestion) => {
        setEndAddress(suggestion.description);
        clearEndSuggestions();
        setShowEndSuggestions(false)
        // ... (similar logic for end address)
        getGeocode({ address: suggestion.description }).then((results) => {
            const { lat, lng } = getLatLng(results[0])
            let latLongList = []
            latLongList.push(lat, lng)
            console.log(latLongList)
            setGeo(latLongList)
        });
    };

    // reset the form fields
    useEffect(() => {
        if (response.success) {
            setTrack('')
            setSensor('')
            navigate(`/`)
        }
    }, [response.success])

    return (
        <form onSubmit={handleSubmit} className={styles.contentBoxCreateTRK}>
            {/* Content box for TRK creation */}

            <div className={styles.flexContainerCreateCol}>
                <h2 className={styles.createTRKTitle}>
                    Create TRK
                </h2>
                <div className={styles.flexContainerNameEntry}>
                    {/* Container for entering TRK name */}
                    <div className={styles.infoTRKName}>
                        TRK Name
                    </div>

                    <div className={styles.content_box4}>
                        <input className={styles.content_box4_input}
                               type="text"
                               required
                               onChange={(e) => setTrack(e.target.value)}
                               value={track}
                        />
                    </div>
                </div>

                <div className={styles.flexContainerTrackerSelection}>
                    {/* Container for selecting tracker options */}
                    <div className={styles.infoSelectTracker}>
                        Select Tracker
                    </div>
                    {devices && <select
                        className={styles.content_box76}
                        id="sensorId"
                        onChange={(e) => setSensor(e.target.value)}>
                        value=sensor>
                        <option value="">Select a Tracker</option>
                        {devices.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>}
                </div>

                <div className={styles.flexContainerStartAddress}>
                    {/* Container for TRK start address information */}
                    <div className={styles.infoStartAddress}>
                        TRK Start Address
                    </div>

                    <input
                        className={styles.content_box76}
                        ref={startInputRef}
                        type="text"
                        id="startAddress"
                        value={startAddress}
                        onChange={handleStartInputChange}
                        required
                    />
                    {startReady && status === 'OK' && showStartSuggestions && (
                        <ul>
                            {startSuggestions.map((suggestion) => (
                                <li className={styles.content_box77}
                                    key={suggestion.place_id}
                                    onClick={() => handleSelectStartSuggestion(suggestion)}
                                >
                                    {suggestion.description}
                                </li>
                            ))}
                        </ul>
                    )}

                    <div className={styles.infoStartAddressDetail}>
                        {/* Details for start address input */}
                        Street Address, City, State
                    </div>
                </div>

                <div className={styles.flexContainerStartAddress}>
                    {/* Container for TRK start address information */}
                    <div className={styles.infoStartAddress}>
                        TRK Destination Address
                    </div>

                    <input
                        className={styles.content_box76}
                        ref={endInputRef}
                        type="text"
                        id="endAddress"
                        value={endAddress}
                        onChange={handleEndInputChange}
                        required
                    />
                    {endReady && status === 'OK' && showEndSuggestions && (
                        <ul>
                            {endSuggestions.map((suggestion) => (
                                <li className={styles.content_box77}
                                    key={suggestion.place_id}
                                    onClick={() => handleSelectEndSuggestion(suggestion)}
                                >
                                    {suggestion.description}
                                </li>
                            ))}
                        </ul>
                    )}

                    <div className={styles.infoStartAddressDetail}>
                        {/* Details for start address input */}
                        Street Address, City, State
                    </div>
                </div>

                <div className={styles.flexContainerArrivalDateTime}>
                    {/* Container for target arrival date and time */}
                    <div className={styles.infoArrivalDateTime}>
                        Target Arrival Date/Time
                    </div>
                    {/* Box for selecting arrival date and time */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                                label="Select Date & Time"
                                value={dateTime}
                                onChange={(e) => setDateTime(e)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    {/*<div className={styles.flexRowArrivalDateTime}>*/}
                    {/*    /!* Row for displaying date and time selection *!/*/}
                    {/*    <p className={styles.highlightDateTimeInstruction}>{`Select Data & Time`}</p>*/}
                    {/*    <img className={styles.imageDateTimeSelection}*/}
                    {/*         src={'/assets/50c0723da218864c4df5d2a34f15a6cf.svg'} alt="alt text"/>*/}
                    {/*</div>*/}
                </div>

                <div className={styles.flexContainerTemperatureAlerts}>
                    {/* Container for temperature alerts section */}
                    <Switch
                        checked={state.temp}
                        onChange={handleChange}
                        name={'temp'}
                    />
                    <p className={styles.highlightTemperatureAlertsText}>
                        Temperature Alerts
                    </p>
                </div>

                <div className={styles.flexContainerHighTempAlertSettings}>
                    {/* Container for high temperature alert settings */}
                    <div className={styles.infoHighTempAlert}>
                        High Temperature Alert Setting
                    </div>

                    {!state.temp ? <div
                            className={state.temp ? styles.contentBoxHighTempSettings : styles.contentBoxHighTempSettingsOff}>
                            {/* Box for high temperature alert settings configuration */}

                            <div className={styles.flexRowHighTempSettings}>
                                {/* Row for high temperature settings display */}
                                <p className={styles.highlightHighTempInstruction}>
                                    High Temperature
                                </p>
                                {state.temp ? <p></p> : <img src={lock} className={styles.lock_icon} alt="locked"/>}
                            </div>
                        </div> :
                        <div className={styles.content_box4}>
                            <input className={styles.content_box4_input}
                                   type="text"
                                   onChange={(e) => setHighTemp(e.target.value)}
                                   value={highTemp}
                            />
                        </div>}

                    <div className={styles.infoHighTempDetails}>
                        {/* Details for high temperature settings input */}
                        Degrees Fahrenheit
                    </div>
                </div>

                <div className={styles.flexContainerHighTempAlertSettings}>
                    {/* Container for high temperature alert settings */}
                    <div className={styles.infoHighTempAlert}>
                        Low Temperature Alert Setting
                    </div>

                    {!state.temp ? <div
                            className={state.temp ? styles.contentBoxHighTempSettings : styles.contentBoxHighTempSettingsOff}>
                            {/* Box for high temperature alert settings configuration */}

                            <div className={styles.flexRowHighTempSettings}>
                                {/* Row for high temperature settings display */}
                                <p className={styles.highlightHighTempInstruction}>
                                    Low Temperature
                                </p>
                                {state.temp ? <p></p> : <img src={lock} className={styles.lock_icon} alt="locked"/>}
                            </div>
                        </div> :
                        <div className={styles.content_box4}>
                            <input className={styles.content_box4_input}
                                   type="text"
                                   onChange={(e) => setLowTemp(e.target.value)}
                                   value={lowTemp}
                            />
                        </div>}
                    <div className={styles.infoHighTempDetails}>
                        {/* Details for high temperature settings input */}
                        Degrees Fahrenheit
                    </div>
                </div>
                <div className={styles.flexContainerHumidityAlerts}>
                    {/* Container for humidity alerts section */}
                    <Switch
                        checked={state.humidity}
                        onChange={handleChange}
                        name={'humidity'}
                    />
                    <p className={styles.highlightHumidityAlertsText}>
                        Humidity Alerts
                    </p>
                </div>

                <div className={styles.flexContainerHighTempAlertSettings}>
                    {/* Container for high temperature alert settings */}
                    <div className={styles.infoHighTempAlert}>
                        High Humidity Alert Setting
                    </div>

                    {!state.humidity ? <div
                            className={state.humidity ? styles.contentBoxHighTempSettings : styles.contentBoxHighTempSettingsOff}>
                            {/* Box for high temperature alert settings configuration */}

                            <div className={styles.flexRowHighTempSettings}>
                                {/* Row for high temperature settings display */}
                                <p className={styles.highlightHighTempInstruction}>
                                    High Humidity
                                </p>
                                {state.humidity ? <p></p> : <img src={lock} className={styles.lock_icon} alt="locked"/>}
                            </div>
                        </div> :
                        <div className={styles.content_box4}>
                            <input className={styles.content_box4_input}
                                   type="text"
                                   onChange={(e) => setHighHumid(e.target.value)}
                                   value={highHumid}
                            />
                        </div>}

                    <div className={styles.infoHighTempDetails}>
                        {/* Details for high temperature settings input */}
                        Value Between 0 and 100
                    </div>
                </div>

                <div className={styles.flexContainerHighTempAlertSettings}>
                    {/* Container for high temperature alert settings */}
                    <div className={styles.infoHighTempAlert}>
                        Low Humidity Alert Setting
                    </div>

                    {!state.humidity ? <div
                            className={state.humidity ? styles.contentBoxHighTempSettings : styles.contentBoxHighTempSettingsOff}>
                            {/* Box for high temperature alert settings configuration */}

                            <div className={styles.flexRowHighTempSettings}>
                                {/* Row for high temperature settings display */}
                                <p className={styles.highlightHighTempInstruction}>
                                    High Humidity
                                </p>
                                {state.humidity ? <p></p> : <img src={lock} className={styles.lock_icon} alt="locked"/>}
                            </div>
                        </div> :
                        <div className={styles.content_box4}>
                            <input className={styles.content_box4_input}
                                   type="text"
                                   onChange={(e) => setLowHumid(e.target.value)}
                                   value={lowHumid}
                            />
                        </div>}

                    <div className={styles.infoHighTempDetails}>
                        {/* Details for high temperature settings input */}
                        Value Between 0 and 100
                    </div>
                </div>

                <div className={styles.flexContainerActionButtons}>
                    {/* Container for action buttons at the bottom */}
                    <button className={styles.btnCancelTRK} onClick={handleClickCreate}>
                        Cancel
                    </button>
                    <button className={styles.btnCreateTRK}>
                        Create TRK
                    </button>
                </div>
            </div>
        </form>
    )
}