import { useReducer, useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import { doc, updateDoc } from "firebase/firestore"
import { useAuthContext } from './useAuthContext'

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null }
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null }
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload }
        default:
            return state
    }
}

export const useFirestoreSetMap = (collection_name) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(true)

    // collection ref

    // only dispatch is not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // update a document
    const updateDocumentSetMap = async (doc_id, setting, lat, long) => {
        dispatch({type: 'IS_PENDING'})

        try {
            // const createdAt = timestamp.fromDate(new Date())
            const docRef = doc(projectFirestore, collection_name, doc_id)

            const updatedDocumentSetMap = await updateDoc(docRef, {
                center: [lat, long],
                click: setting
            })
            dispatchIfNotCancelled({type: 'ADDED_DOCUMENT', payload: updatedDocumentSetMap})
        }

        catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
        }
    }

    useEffect(() => {
        setIsCancelled(false)
    }, [])

    return { updateDocumentSetMap, response }

}